import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { connect } from 'react-redux';
import {Redirect, Route, Switch, withRouter } from 'react-router';
import {
    AppBar,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Tab,
    Tabs,
    Toolbar,
    Divider,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import authActions from './pages/login/auth-actions';
import Info from "./components/info/Info";
import MessageDialog from "./components/dialogs/MessageDialog";
import ConfirmDialog from "./components/dialogs/ConfirmDialog";
import {hasRole} from "./services/user";
import {DndProvider} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import BackgroundProgress from "./components/background-progress/BackgroundProgress";
import {withTranslation} from "react-i18next";
import {topBarSettings} from "./services/common";
import {Link} from "react-router-dom";
import LanguageSwitcher from "./components/button/LanguageSwitcher";
import cn from "classnames";
import Tooltip from '@material-ui/core/Tooltip';
import messageDialogActions from "./components/dialogs/messageDialog-acions";
import AssignmentIcon from '@material-ui/icons/Assignment';
import AdbIcon from '@material-ui/icons/Adb';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ProcessPage from "./pages/process/ProcessPage";
import ExecutorPage from "./pages/executor/ExecutorPage";
import MessageIcon from '@material-ui/icons/Message';
import WhatsappPage from "./pages/whatsapp/WhatsappPage";
import BuildIcon from '@material-ui/icons/Build';
import SettingsPage from "./pages/settings/SettingsPage";
import LoginPage from "./pages/login/LoginPage";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import TasksPage from "./pages/tasks/TasksPage";
import AssessmentIcon from '@material-ui/icons/Assessment';
import ReportsPage from "./pages/reports/ReportsPage";
import SegmentPage from "./pages/segment/SegmentPage";

const showLogo = process.env.REACT_APP_VARIANT !== 'PROD';

const routes = [
    { path: '/process', label: 'Processes', icon: AssignmentIcon, component: ProcessPage },
    { path: '/tasks', label: 'Tasks', icon: ClearAllIcon, component: TasksPage },
    { path: '/executor', label: 'Executors', icon: AdbIcon, component: ExecutorPage },
    { path: '/segment', label: 'Segments', icon: GroupAddIcon, component: SegmentPage },
    { path: '/whatsapp', label: 'Whatsapp', icon: MessageIcon, component: WhatsappPage },
    { path: '/reports', label: 'Reports', icon: AssessmentIcon, component: ReportsPage },
    { path: '/settings', label: 'Settings', icon: BuildIcon, component: SettingsPage }
];

export default
@withRouter
@connect(state => ({
    token: state.auth.token,
    currentUser: state.auth.user,
}), {
    fetchCurrentUser: authActions.fetchCurrentUser,
    logout: authActions.logout,
    showMessage: messageDialogActions.show,
})
@withStyles(theme => ({
    root: {
        display: 'flex',
    },
    course: {
        marginRight:  theme.spacing(1),
        marginLeft:  theme.spacing(0.5),
    },
    hidePrint: {
        '@media print': {
            display: 'none',
        },
    },
    userAccessDenied: {
        minWidth: 300,
        maxWidth: 500,
        padding: 20,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 20,
        marginBottom: 20,
        border: '1px solid #555555',
        borderRadius: 10,
    },
    tabs: {
        backgroundColor: '#fff',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.primary.light,
        '& .MuiTabs-scrollButtons': {
                color: theme.palette.text.primary,
        },
        '& .MuiTab-root': {
            padding: '3px 6px',
        },
        '& .MuiTab-wrapper': {
            lineHeight: 1.75,
            fontSize: 10,
            '& > *:first-child': {
                fontSize: 24,
                marginBottom: 0,
            }
        },
        '& .MuiTab-labelIcon': {
            minHeight: 48,
        },
    },
    topBarItemSpacing: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    topBarPadding: {
        height: 48,
    },
    topBar: {
        zIndex: 1210,
        overflow: 'hidden',
    },
    topBarMaxHeight: {
        maxHeight: '100%',
    },
    logo: {
        height: 40,
        display: 'inline-block',
        borderRight: '1px solid #fff',
    },
    slogan: {
        color: '#fff',
        padding: '2px 0 2px 0',
        lineHeight: '16px',
        whiteSpace: 'pre-wrap',
        textTransform: 'uppercase',
        fontWeight: 500,
        position: 'absolute',
    },
    fullWidthContainer: {
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    smallToolbar: {

    },
    smallToolbarIcon: {
        color: theme.palette.getContrastText(theme.palette.primary.main),

        display: 'inline-block',
        marginRight: theme.spacing(1),
        padding: theme.spacing(0, 0.5),
    },
    smallToolbarIconActive: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.getContrastText(theme.palette.secondary.main),
    }
}))
@withTranslation()
class App extends React.Component {
    state = {
        value: 0,
        userMenuOpen: false,
        anchorEl: null,
        currentCourses: [],
        topBarStyle: {
            height: topBarSettings.startHeight,
            minHeight: topBarSettings.startHeight,
            fontSize: topBarSettings.startFontSize,
        },
        topBarLogoStyle: {
            height: topBarSettings.logoStartHeight,
            minHeight: topBarSettings.logoStartHeight,
            paddingRight: topBarSettings.logoPaddingStart,
            marginRight: topBarSettings.logoPaddingStart,
        },
        sloganStyle: {
            opacity: 1,
        },
        smallBarStyle: {
            opacity: 0,
            display: 'none',
        },
    };

    roleRoutes = [];

    componentDidMount() {
        const { token } = this.props;

        if (token) {
            //fetchCurrentUser();
        }

        window.addEventListener('scroll', this.handleScroll);

        this.props.history.listen(() => {
            this.sendUrlHistory();
        });
        this.sendUrlHistory();
    }

    sendUrlHistory = () => {
        if (window.location.hostname === 'localhost') {
            return;
        }

        if (window.yaCounter69275761) {
            window.yaCounter69275761.hit(window.location.pathname);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const {
            startHeight,
            minHeight,
            logoMinHeight,
            logoStartHeight,
            minFontSize,
            startFontSize,
            logoPaddingStart,
            logoPaddingMin,
        } = topBarSettings;
        const { topBarStyle, topBarLogoStyle, sloganStyle, smallBarStyle } = this.state;
        let topBarHeight = minHeight;
        if ((startHeight - minHeight) > window.scrollY / 3.0) {
            topBarHeight = startHeight - window.scrollY / 3.0;
        }

        if (topBarStyle.height !== topBarHeight) {
            const percent = (topBarHeight - minHeight) / (startHeight - minHeight);
            const logoHeight = logoMinHeight + percent * (logoStartHeight - logoMinHeight);
            const logoPadding = logoPaddingMin + percent * (logoPaddingStart - logoPaddingMin);

            this.setState({
                topBarStyle: {
                    ...topBarStyle,
                    height: topBarHeight,
                    minHeight: topBarHeight,
                    fontSize: minFontSize + percent * (startFontSize - minFontSize),
                },
                topBarLogoStyle: {
                    ...topBarLogoStyle,
                    height: logoHeight,
                    minHeight: logoHeight,
                    paddingRight: logoPadding,
                    marginRight: logoPadding,
                },
                sloganStyle: {
                    ...sloganStyle,
                    opacity: percent,
                    display: percent < 0.1 ? 'none' : 'inline',
                },
                smallBarStyle: {
                    ...smallBarStyle,
                    opacity: 1 - percent,
                    display: percent < 0.8 ? 'block' : 'none',
                }
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { token, } = this.props;

        if (prevProps.token !== token && token) {
            //fetchCurrentUser();
        }
    }

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleLogout = () => {
        this.handleClose();

        this.props.logout();
    };

    render() {
        const { classes, currentUser, location, t, i18n, token } = this.props;
        const { anchorEl, topBarStyle, topBarLogoStyle, sloganStyle, smallBarStyle } = this.state;
        const userMenuOpen = Boolean(anchorEl);

        this.roleRoutes = routes.filter(route => hasRole(route.role));
        this.roleRoutes = routes;
        let tabIndex = this.roleRoutes.findIndex(route => new RegExp('^'+route.path).test(location.pathname));

        if (tabIndex < 0) {
            tabIndex = false;
        }

        return <DndProvider backend={HTML5Backend}>
            <AppBar position="fixed" className={cn(classes.hidePrint, classes.topBar)} elevation={2} style={topBarStyle}>
                <Toolbar variant="dense" style={topBarStyle}>
                    <div className={classes.topBarMaxHeight}>
                        <Button component="a" href="/" size="small" variant="text" style={topBarStyle}>
                            {showLogo && <img src={'/img/logo_'+ (i18n.language === 'ru' ? 'ru' : 'en') +'.png'} alt="TT" style={topBarLogoStyle} className={cn(classes.logo, classes.topBarMaxHeight)}/>}
                        </Button>
                    </div>
                    <div className={cn(classes.fullWidthContainer, classes.topBarMaxHeight)} style={topBarStyle}>
                        <span className={classes.slogan} style={sloganStyle}>{ t("Customer Relationship Network") }</span>

                        {(currentUser && this.props.location.pathname !== '/login') ?
                            <div style={smallBarStyle} className={classes.smallToolbar}>
                                {this.roleRoutes.map((route, idx) => {
                                    const Icon = route.icon;
                                    return <Tooltip key={idx} title={t(route.label)}><Link
                                        to={this.roleRoutes[idx].path}
                                        disabled={route.disabled}
                                        size="small"
                                        className={cn(classes.smallToolbarIcon, {[classes.smallToolbarIconActive]: tabIndex === idx})}
                                    ><Icon fontSize="small"/></Link></Tooltip>;
                                })}
                            </div>
                            : null
                        }
                    </div>
                    <LanguageSwitcher className={classes.topBarItemSpacing}/>
                    { token ?
                        <div>
                            <span>{'logged'}</span>
                            <IconButton
                                aria-owns={userMenuOpen ? 'menu-user' : null}
                                aria-haspopup="true"
                                onClick={this.handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-user"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={userMenuOpen}
                                onClose={this.handleClose}
                            >
                                <Divider/>
                                <MenuItem onClick={this.handleLogout}>{t("Exit")}</MenuItem>
                            </Menu>
                        </div>
                        :
                        null
                    }
                    { currentUser ?
                        <div>
                            <span>{currentUser.username}</span>
                            <IconButton
                                aria-owns={userMenuOpen ? 'menu-user' : null}
                                aria-haspopup="true"
                                onClick={this.handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-user"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={userMenuOpen}
                                onClose={this.handleClose}
                            >
                                <Divider/>
                                <MenuItem onClick={this.handleLogout}>{t("Exit")}</MenuItem>
                            </Menu>
                        </div>
                        : null
                    }
                </Toolbar>
            </AppBar>
                <AppBar position="static" className={classes.hidePrint}>
                    <div className={classes.topBarPadding}> </div>
                    { this.props.location.pathname !== '/login' ?
                        <Tabs
                            value={tabIndex}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            className={classes.tabs}
                        >
                            {this.roleRoutes.map((route, idx) => {
                                const Icon = route.icon;
                                return <Tab key={idx}
                                     component={Link}
                                     to={this.roleRoutes[idx].path}
                                     label={t(route.label)}
                                     icon={<Icon/>}
                                     disabled={route.disabled}
                                />;
                            })}
                        </Tabs>
                     : null }
                </AppBar>
            <div className={classes.root}>
                <Switch>
                    <Route exact path="/" render={() => (<Redirect to="/process"/>)}/>
                    {routes.map((route,idx) => <Route key={idx} path={route.path} component={route.component} userRole={route.role}/>)}
                    <Route path="/login"             component={LoginPage}/>
                    {/*
                    <Route path="/password-recovery" component={RecoveryPage}/>
                    <AuthenticatedRoute path="/download" component={Download}/>
                    */}
                </Switch>

                { currentUser && currentUser.roles && currentUser.roles.length === 0 ?
                    <div className={classes.userAccessDenied}>
                        <h3>{t('Access denied')}</h3>
                        <p>{t('accessDeniedEmptyRoles')}</p>
                    </div>
                    : null
                }
            </div>
            <Info/>
            <MessageDialog />
            <ConfirmDialog />

            <BackgroundProgress/>
        </DndProvider>;
    }
}
